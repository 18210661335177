@import '~antd/dist/antd.css';

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


@font-face {
    font-family: "Gotham-Light";
    src: url("fonts/Gotham-Light.otf");
}

@font-face {
    font-family: "Cubano";
    src: url("fonts/Cubano.ttf");
}


@keyframes animatedBackground {
    from {
      background-position: 0 0;
    }
    to {
      background-position: 100% 0;
    }
}

@-webkit-keyframes mover {
    0% { transform: translateY(0); }
    100% { transform: translateY(-20px); }
}
@keyframes mover {
    0% { transform: translateY(0); }
    100% { transform: translateY(-20px); }
}

@-webkit-keyframes movermas {
    0% { transform: translateY(0); }
    100% { transform: translateY(-35px); }
}
@keyframes movermas {
    0% { transform: translateY(0); }
    100% { transform: translateY(-35px); }
}

@keyframes rotate {
    0% { transform: rotate(10deg); }
    100% { transform: rotate(360deg); }
}

@-webkit-keyframes rotate {
    0% { transform: rotate(10deg); }
    100% { transform: rotate(360deg); }
}

body {
    background-image: url("images/ranking_background.png");
    background-size: cover;
    background-repeat: no-repeat;
    box-sizing: border-box;
    background-attachment: fixed;
    padding: 0;
    margin: 0;
    -webkit-animation: animatedBackground 20s infinite  alternate;
    animation: animatedBackground 20s infinite  alternate;
}

.container {
    /* background-image: url(../images/dots.png); */
    /* background-position: right; */
    /* background-color: #1877ff; */
    width: 98%;
    /* box-shadow: rgb(100 100 111 / 20%) 0px 7px 29px 0px; */
    /* border-radius: 0.7rem; */
    margin: 1.5rem auto;
    padding: 0.5rem;
    /* border: 3px solid #c9dcfa; */
    /* background-size: contain; */
    background: border-box;
    /* background-blend-mode: darken; */
}

.ranking {
    display: flex;
    height: 12vh;
    align-items: center;
    justify-content: center;
}

.ranking h1 {
    font-size: 2rem;
}

.ranking-title {
    border-bottom: 1px solid #c9dcfa;
}

.ranking-data {
    display: flex;
    justify-content: space-around;
    padding: 0.5rem 0;
}

.position-table table {
    border-collapse: collapse;
    width: 100%;
}

.position-table table thead, .ant-table {
  background: transparent;
}

.position-table table td, .position-table table th {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 1rem 0.5rem;
    background: transparent;
}

.ant-table-tbody > tr.ant-table-row:hover > td, .ant-table-tbody > tr > td.ant-table-cell-row-hover {
  background: none;
}

.position-table table th {
  font-weight: bold;
  color: white;
}

.position-table table tr:nth-child(even), .position-table table th {
    background-color: #192ec5;
}

.position-table .ant-pagination-item a {
  color: white;
}

.position-table .ant-table-thead th.ant-table-column-sort {
  background: #4857c2;
}

.position-table .active {
  color: green;
}

.position-table .ant-pagination-item,
.position-table .ant-select:not(.ant-select-customize-input) .ant-select-selector,
.position-table .ant-pagination-prev .ant-pagination-item-link,
.position-table .ant-pagination-next .ant-pagination-item-link {
  background: #192ec5;
}

h1 {
    text-align: center;
    font-family: "Cubano";
    color: white;
    letter-spacing: 0.2rem;
    font-size: 1.5rem;
}

h2 {
    font-family: "Cubano";
    color: white;
    letter-spacing: 0.1rem;
    font-size: 1.1rem;
}

.position-table tr {
    font-family: "Cubano";
    color: white;
    letter-spacing: 0.1rem;
    font-size: 0.9rem;
}

a {text-decoration: none;}
.position-table span, .position-table td, .ant-table-title {
    font-family: "Gotham-Light";
    color: white;
}

.ant-table-title {
    text-align: center;
    font-weight: bold;
    font-size: 20px;
}

.imagen_animacion {
    width: 10%;
    position: absolute;
    bottom: 50px;

}

.imagen_animacion img {
    width: 100%;
}

.derecha {
    right: 25px;
    -webkit-animation: mover 3s infinite  alternate;
    animation: mover 3s infinite  alternate;
}

.izquierda {
    left: 25px;
    -webkit-animation: mover 2s infinite  alternate;
    animation: mover 2s infinite  alternate;
    animation: rotate 55 infinite  alternate;
    -webkit-animation: rotate 55s infinite  alternate;
}

.container_cards {
    border-radius: 35px;
    padding: 3rem;
}

.flex {
    display: flex;
    justify-content: space-between;
}

.container_cards .card {
    background-color: white;
    width: 30%;
    text-align: center;
    color: black;
    border-radius: 1rem;
    box-shadow: -1px 0px 20px 0px #4f5897;
    padding-bottom: 0.5rem;
    border-top-left-radius: 0rem;
    border-top-right-radius: 0rem;
}

.container_cards .card:hover {
    cursor: pointer;
    background-color: rgb(49, 22, 225);
    box-shadow: -1px 0px 20px 0px #ffffff;
    transition: all 0.3s ease-out;
}

.container_cards .card:hover h2{
    color: white;
    transition: all 0.3s ease-out;
}

.container_cards .card h2 {
    font-family: "Gotham-Light";
    color: black;
}

.container_cards .card img {
    width: 100%;
}

.final_phrase {
    color: white;
    margin-top: 3rem;
    text-align: center;
    padding-top: 2rem;
    border-top: 1px solid;
    font-family: "Gotham-Light";
}

@media (max-width: 1280px) {

    .container {
        width: 100%;
        padding: 0;
        margin: 0;
    }

    .container_cards {
        padding: 2rem;
    }

    .container_cards .card {
        width: 90%;
        margin: 1rem;
    }
}

@media (max-width: 720px) {

    .container {
        width: 100%;
        padding: 0;
        margin: 0;
    }

    table td, table tr {
        font-size: 0.6rem;
    }

    h1 {
        font-size: 1.5rem  !important;
    }

    h2 {
        font-size: 1rem;
    }

    .imagen_animacion {
        display: none;
    }

    .flex {
        display: block;
    }

    .container_cards .card {
        width: 90%;
        margin: 2rem auto;
    }
}

.back{
  float:lef;
  margin-right:20px;
  font-family: "Cubano";
}
.back a{
  color:rgb(232, 232, 232);
    font-family: "Gotham-Light";
    text-decoration: none;
}
.back a:hover{
  color:white;
    text-decoration: underline;
    transition: all 0.3s ease-out;
}

i {
    color: white;
    margin-right: 7px;
}

.subtitle
  {
    width: 100%;
    text-align: center;
    font-family: "Gotham-Light";
    color: white;
    margin-bottom:20px;
  }

.created-table {
    display: flex;
    justify-content: space-around;
}

.logged-in {
    color: white;
    text-align: right;
}
